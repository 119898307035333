import React, { useState } from 'react';
import {
  AppBar, Toolbar, Typography, Box, Button, Card, CardContent,
  CardActions, Grid, IconButton, Paper, TextField,
  ThemeProvider, createTheme, CssBaseline, Switch, FormControlLabel,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  List, ListItem, ListItemText, Divider, Select, MenuItem,
  FormControl, InputLabel
} from '@mui/material';
import {
  CalendarToday, AccessTime, Person, Notifications, Settings,
  ChevronLeft, ChevronRight, Close, Group, BarChart, AssessmentOutlined,
  Warning
} from '@mui/icons-material';
import { BarChart as RechartsBarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';

interface NavButtonProps {
  icon: React.ElementType;
  label: string;
  onClick: () => void;
  isActive: boolean;
}

const NavButton: React.FC<NavButtonProps> = ({ icon: Icon, label, onClick, isActive }) => (
  <Button
    onClick={onClick}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: isActive ? 'primary.main' : 'text.secondary',
      '&:hover': {
        backgroundColor: 'action.hover',
      },
    }}
  >
    <Icon />
    <Typography variant="caption">{label}</Typography>
  </Button>
);

const ShiftStatusLegend: React.FC = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 2, mt: 2 }}>
    {[
      { color: 'success.light', label: '確定済み' },
      { color: 'warning.light', label: '未確定' },
      { color: 'error.light', label: '要修正' },
      { color: 'grey.300', label: '未入力' },
    ].map(({ color, label }) => (
      <Box key={label} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: 16, height: 16, backgroundColor: color, mr: 1, borderRadius: 1 }} />
        <Typography variant="body2">{label}</Typography>
      </Box>
    ))}
  </Box>
);

interface ShiftModalProps {
  onClose: () => void;
  date: Date;
}

const ShiftModal: React.FC<ShiftModalProps> = ({ onClose, date }) => (
  <Card sx={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', maxWidth: 400, width: '100%', p: 2 }}>
    <CardContent>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">シフト入力: {date.toLocaleDateString()}</Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      <TextField
        label="開始時間"
        type="time"
        fullWidth
        margin="normal"
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="終了時間"
        type="time"
        fullWidth
        margin="normal"
        InputLabelProps={{ shrink: true }}
      />
    </CardContent>
    <CardActions sx={{ justifyContent: 'flex-end' }}>
      <Button onClick={onClose}>キャンセル</Button>
      <Button variant="contained" color="primary">保存</Button>
    </CardActions>
  </Card>
);

const ShiftCalendar: React.FC = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const prevMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
  };

  const nextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };

  const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();

  const handleDateClick = (day: number) => {
    const clickedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    setSelectedDate(clickedDate);
    setShowModal(true);
  };

  // シフト状態を取得する関数
  const getShiftStatus = (day: number): 'success.light' | 'warning.light' | 'error.light' | 'grey.300' => {
    // この例では、日付に基づいて疑似ランダムにシフト状態を割り当てています
    // 実際のアプリケーションでは、バックエンドから取得したデータに基づいて
    // シフト状態を決定するロジックを実装します
    const statuses = ['success.light', 'warning.light', 'error.light', 'grey.300'];
    const randomIndex = Math.floor((day * 13) % statuses.length); // 疑似ランダム
    return statuses[randomIndex] as 'success.light' | 'warning.light' | 'error.light' | 'grey.300';
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5">月間シフト管理</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={prevMonth}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h6">
            {currentDate.getFullYear()}年 {currentDate.getMonth() + 1}月
          </Typography>
          <IconButton onClick={nextMonth}>
            <ChevronRight />
          </IconButton>
        </Box>
      </Box>

      <Grid container spacing={1} sx={{ backgroundColor: 'grey.100', p: 1, borderRadius: 1 }}>
        {['日', '月', '火', '水', '木', '金', '土'].map(day => (
          <Grid item key={day} xs={12/7}>
            <Typography align="center" sx={{ fontWeight: 'medium', color: 'black' }}>{day}</Typography>
          </Grid>
        ))}
        {Array.from({ length: firstDayOfMonth }).map((_, index) => (
          <Grid item key={`empty-${index}`} xs={12/7} />
        ))}
        {Array.from({ length: daysInMonth }).map((_, index) => {
          const day = index + 1;
          const shiftStatus = getShiftStatus(day);
          return (
            <Grid item key={day} xs={12/7}>
              <Button
                onClick={() => handleDateClick(day)}
                sx={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: shiftStatus,
                  '&:hover': { opacity: 0.8 },
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  color: 'black',
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                }}
              >
                <Typography color="inherit">{day}</Typography>
                <Typography variant="caption" color="inherit">9:00-18:00</Typography>
              </Button>
            </Grid>
          );
        })}
      </Grid>

      <ShiftStatusLegend />

      {showModal && selectedDate && (
        <ShiftModal onClose={() => setShowModal(false)} date={selectedDate} />
      )}
    </Box>
  );
};

const AttendancePage: React.FC = () => {
  const attendanceData = [
    { month: '1月', 所定労働時間: 160, 実労働時間: 165 },
    { month: '2月', 所定労働時間: 160, 実労働時間: 158 },
    { month: '3月', 所定労働時間: 160, 実労働時間: 162 },
    { month: '4月', 所定労働時間: 160, 実労働時間: 170 },
    { month: '5月', 所定労働時間: 160, 実労働時間: 163 },
    { month: '6月', 所定労働時間: 160, 実労働時間: 159 },
  ];

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 2 }}>勤怠管理</Typography>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h6" sx={{ mb: 2 }}>過去6ヶ月の勤怠状況</Typography>
          <Box sx={{ height: 300 }}>
            <ResponsiveContainer>
              <RechartsBarChart data={attendanceData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="所定労働時間" fill="#8884d8" />
                <Bar dataKey="実労働時間" fill="#82ca9d" />
              </RechartsBarChart>
            </ResponsiveContainer>
          </Box>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Typography variant="h6" sx={{ mb: 2 }}>今月の勤怠サマリー</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">総労働時間</Typography>
              <Typography variant="h4">160時間</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">残業時間</Typography>
              <Typography variant="h4" color="warning.main">15時間</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">休暇取得日数</Typography>
              <Typography variant="h4" color="success.main">2日</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">遅刻回数</Typography>
              <Typography variant="h4" color="error.main">1回</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

const ProfilePage: React.FC = () => (
  <Box>
    <Typography variant="h5" sx={{ mb: 2 }}>プロフィール</Typography>
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Box sx={{ width: 80, height: 80, backgroundColor: 'grey.300', borderRadius: '50%', mr: 2 }} />
          <Box>
            <Typography variant="h6">山田 太郎</Typography>
            <Typography variant="body2" color="text.secondary">営業部</Typography>
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body2" color="text.secondary">社員番号</Typography>
            <Typography variant="body1">EMP001</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="text.secondary">入社日</Typography>
            <Typography variant="body1">2020年4月1日</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="text.secondary">メールアドレス</Typography>
            <Typography variant="body1">t.yamada@example.com</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="text.secondary">電話番号</Typography>
            <Typography variant="body1">090-1234-5678</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </Box>
);

const NotificationPage: React.FC = () => (
  <Box>
    <Typography variant="h5" sx={{ mb: 2 }}>通知</Typography>
    <List>
      {[
        { title: 'シフト承認のお知らせ', content: '来週のシフトが承認されました。', date: '2023/6/20 10:30' },
        { title: '勤怠修正依頼', content: '先週の勤怠記録に修正が必要です。', date: '2023/6/19 15:45' },
        { title: '有給休暇申請の承認', content: '7/1の有給休暇申請が承認されました。', date: '2023/6/18 09:15' },
      ].map((notification, index) => (
        <React.Fragment key={index}>
          <ListItem>
            <ListItemText
              primary={notification.title}
              secondary={
                <React.Fragment>
                  <Typography variant="body2" component="span">{notification.content}</Typography>
                  <Typography variant="caption" component="p">{notification.date}</Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          {index < 2 && <Divider />}
        </React.Fragment>
      ))}
    </List>
  </Box>
);

const SettingsPage: React.FC = () => (
  <Box>
    <Typography variant="h5" sx={{ mb: 2 }}>設定</Typography>
    <Card>
      <CardContent>
        <List>
          <ListItem>
            <FormControlLabel
              control={<Switch />}
              label="メールでの通知を受け取る"
            />
          </ListItem>
          <ListItem>
            <FormControlLabel
              control={<Switch />}
              label="プッシュ通知を受け取る"
            />
          </ListItem>
          <ListItem>
            <FormControl fullWidth>
              <InputLabel>言語設定</InputLabel>
              <Select
                value="日本語"
                label="言語設定"
              >
                <MenuItem value="日本語">日本語</MenuItem>
                <MenuItem value="English">English</MenuItem>
              </Select>
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControl fullWidth>
              <InputLabel>テーマ</InputLabel>
              <Select
                value="ライト"
                label="テーマ"
              >
                <MenuItem value="ライト">ライト</MenuItem>
                <MenuItem value="ダーク">ダーク</MenuItem>
              </Select>
            </FormControl>
          </ListItem>
        </List>
      </CardContent>
    </Card>
  </Box>
);

const AdminNavButton: React.FC<NavButtonProps> = ({ icon: Icon, label, onClick, isActive }) => (
  <Button
    onClick={onClick}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      py: 2,
      color: isActive ? 'primary.main' : 'text.primary',
      bgcolor: isActive ? 'action.selected' : 'transparent',
      '&:hover': {
        bgcolor: 'action.hover',
      },
    }}
  >
    <Icon />
    <Typography variant="caption">{label}</Typography>
  </Button>
);

const ShiftManagement: React.FC = () => (
  <Box>
    <Typography variant="h5" sx={{ mb: 2 }}>シフト管理</Typography>
    <Grid container spacing={2}>
      {[
        { title: 'シフトの割り当て', description: '従業員の希望シフトを元にシフトを作成します。' },
        { title: '月間法定労働時間のチェック', description: 'シフトが法定労働時間の範囲内に収まるかをチェックします。' },
        { title: 'シフトの確認と承認', description: '管理者がシフトの変更や申請を確認・承認します。' },
        { title: 'シフトの通知', description: 'シフトの決定や変更を従業員に通知します。' },
      ].map((item, index) => (
        <Grid item xs={12} sm={6} key={index}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>{item.title}</Typography>
              <Typography variant="body2" color="text.secondary">{item.description}</Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Box>
);

const AttendanceManagement: React.FC = () => (
  <Box>
    <Typography variant="h5" sx={{ mb: 2 }}>勤怠管理</Typography>
    <Grid container spacing={2}>
      {[
        { title: '勤務時間の集計', description: '各従業員の勤務時間を集計します。' },
        { title: '残業時間の管理', description: '残業時間を管理し、適切な給与計算を支援します。' },
        { title: '休暇・有給の申請と管理', description: '休暇や有給の申請を管理し、承認します。' },
      ].map((item, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>{item.title}</Typography>
              <Typography variant="body2" color="text.secondary">{item.description}</Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Box>
);

const WorkTimeManagement: React.FC = () => {
  const monthlyWorkTimeData = [
    { name: '4月', 総労働時間: 160, 残業時間: 10 },
    { name: '5月', 総労働時間: 168, 残業時間: 18 },
    { name: '6月', 総労働時間: 176, 残業時間: 26 },
  ];

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 2 }}>労働時間管理</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>月間労働時間の集計</Typography>
              <Typography variant="body2" color="text.secondary">各従業員の月間の総労働時間を集計します。</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>残業時間の管理</Typography>
              <Typography variant="body2" color="text.secondary">月間の残業時間を管理します。</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>労働時間推移</Typography>
          <Box sx={{ height: 300 }}>
            <ResponsiveContainer>
              <RechartsBarChart data={monthlyWorkTimeData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="総労働時間" fill="#8884d8" />
                <Bar dataKey="残業時間" fill="#82ca9d" />
              </RechartsBarChart>
            </ResponsiveContainer>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

const IntervalManagement: React.FC = () => {
  const intervalData = [
    { id: 1, 従業員名: '山田太郎', 前回勤務終了: '2023-06-20 22:00', 次回勤務開始: '2023-06-21 09:00', インターバル: '11時間', 状態: '適正' },
    { id: 2, 従業員名: '佐藤花子', 前回勤務終了: '2023-06-20 23:30', 次回勤務開始: '2023-06-21 08:00', インターバル: '8時間30分', 状態: '要注意' },
    { id: 3, 従業員名: '鈴木一郎', 前回勤務終了: '2023-06-21 01:00', 次回勤務開始: '2023-06-21 10:00', インターバル: '9時間', 状態: '適正' },
  ];

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 2 }}>勤務間インターバル管理</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>従業員名</TableCell>
              <TableCell>前回勤務終了</TableCell>
              <TableCell>次回勤務開始</TableCell>
              <TableCell>インターバル</TableCell>
              <TableCell>状態</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {intervalData.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.従業員名}</TableCell>
                <TableCell>{row.前回勤務終了}</TableCell>
                <TableCell>{row.次回勤務開始}</TableCell>
                <TableCell>{row.インターバル}</TableCell>
                <TableCell sx={{ color: row.状態 === '要注意' ? 'error.main' : 'success.main' }}>{row.状態}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const UserManagement: React.FC = () => {
  const userData = [
    { id: 1, 名前: '山田太郎', 部署: '営業部', 役職: '主任', メール: 'yamada@example.com' },
    { id: 2, 名前: '佐藤花子', 部署: '人事部', 役職: 'マネージャー', メール: 'sato@example.com' },
    { id: 3, 名前: '鈴木一郎', 部署: '技術部', 役職: '一般社員', メール: 'suzuki@example.com' },
    { id: 4, 名前: '田中美咲', 部署: '経理部', 役職: '課長', メール: 'tanaka@example.com' },
  ];

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 2 }}>ユーザー管理</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>名前</TableCell>
              <TableCell>部署</TableCell>
              <TableCell>役職</TableCell>
              <TableCell>メール</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userData.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.名前}</TableCell>
                <TableCell>{user.部署}</TableCell>
                <TableCell>{user.役職}</TableCell>
                <TableCell>{user.メール}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const ReportManagement: React.FC = () => {
  const monthlyData = [
    { name: '1月', 総労働時間: 160, 残業時間: 10 },
    { name: '2月', 総労働時間: 152, 残業時間: 8 },
    { name: '3月', 総労働時間: 168, 残業時間: 15 },
    { name: '4月', 総労働時間: 160, 残業時間: 12 },
    { name: '5月', 総労働時間: 176, 残業時間: 20 },
    { name: '6月', 総労働時間: 168, 残業時間: 16 },
  ];

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 2 }}>レポート</Typography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>勤務時間レポート</Typography>
              <Typography variant="body2" color="text.secondary">勤務時間の詳細レポートを生成します。</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>残業時間レポート</Typography>
              <Typography variant="body2" color="text.secondary">残業時間の詳細レポートを生成します。</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>月別労働時間推移</Typography>
          <Box sx={{ height: 300 }}>
            <ResponsiveContainer>
              <LineChart data={monthlyData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="総労働時間" stroke="#8884d8" />
                <Line type="monotone" dataKey="残業時間" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

const AdminPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState('シフト管理');

  const tabs = [
    { label: 'シフト管理', icon: CalendarToday },
    { label: '勤怠管理', icon: AccessTime },
    { label: '労働時間管理', icon: BarChart },
    { label: '勤務間インターバル管理', icon: Warning },
    { label: 'ユーザー管理', icon: Group },
    { label: 'レポート', icon: AssessmentOutlined },
  ];

  const renderTabContent = () => {
    switch (activeTab) {
      case 'シフト管理':
        return <ShiftManagement />;
      case '勤怠管理':
        return <AttendanceManagement />;
      case '労働時間管理':
        return <WorkTimeManagement />;
      case '勤務間インターバル管理':
        return <IntervalManagement />;
      case 'ユーザー管理':
        return <UserManagement />;
      case 'レポート':
        return <ReportManagement />;
      default:
        return <Typography>機能は開発中です</Typography>;
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>管理者ページ</Typography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {tabs.map((tab) => (
          <Grid item xs={6} sm={4} md={2} key={tab.label}>
            <AdminNavButton
              icon={tab.icon}
              label={tab.label}
              onClick={() => setActiveTab(tab.label)}
              isActive={activeTab === tab.label}
            />
          </Grid>
        ))}
      </Grid>
      <Paper sx={{ p: 2 }}>
        {renderTabContent()}
      </Paper>
    </Box>
  );
};

const ProfessionalDashboard: React.FC = () => {
  const [activePage, setActivePage] = useState('シフト');
  const [showAdminPage, setShowAdminPage] = useState(false);
  const theme = createTheme();

  const pages = [
    { icon: CalendarToday, label: 'シフト' },
    { icon: AccessTime, label: '勤怠' },
    { icon: Person, label: 'プロフィール' },
    { icon: Notifications, label: '通知' },
    { icon: Settings, label: '設定' },
  ];

  const renderActivePage = () => {
    if (showAdminPage) {
      return <AdminPage />;
    }

    switch (activePage) {
      case 'シフト':
        return <ShiftCalendar />;
      case '勤怠':
        return <AttendancePage />;
      case 'プロフィール':
        return <ProfilePage />;
      case '通知':
        return <NotificationPage />;
        case '設定':
          return <SettingsPage />;
        default:
          return null;
      }
    };

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                シフト管理システム
              </Typography>
              <Button color="inherit" onClick={() => setShowAdminPage(!showAdminPage)}>
                {showAdminPage ? 'ユーザーページへ' : '管理者ページへ'}
              </Button>
            </Toolbar>
          </AppBar>
          {!showAdminPage && (
            <Paper sx={{ display: 'flex', justifyContent: 'space-around', py: 1 }}>
              {pages.map(({ icon, label }) => (
                <NavButton
                  key={label}
                  icon={icon}
                  label={label}
                  onClick={() => setActivePage(label)}
                  isActive={activePage === label}
                />
              ))}
            </Paper>
          )}
          <Box sx={{ flexGrow: 1, p: 3 }}>
            {renderActivePage()}
          </Box>
        </Box>
      </ThemeProvider>
    );
  };

  export default ProfessionalDashboard;
